import React from 'react'
import { Link, animateScroll as scroll } from "react-scroll";

import Layout from '../components/layout'
import SEO from '../components/seo'

import { FaHtml5 } from 'react-icons/fa';
import { FaCss3 } from 'react-icons/fa';
import { FaLess } from 'react-icons/fa';
import { FaSass } from 'react-icons/fa';
import { FaPhp } from 'react-icons/fa';
// //MySql
// //jQuery
import { FaJs } from 'react-icons/fa';
import { FaGit } from 'react-icons/fa';
import { FaBitbucket } from 'react-icons/fa';
import { FaJira } from 'react-icons/fa';
import { FaLaravel } from 'react-icons/fa';
import { FaWordpress } from 'react-icons/fa';
import { FaHubspot } from 'react-icons/fa';
import { FaDrupal } from 'react-icons/fa';
import { FaJoomla } from 'react-icons/fa';
import { FaAdobe } from 'react-icons/fa';

import { FaPencilAlt } from 'react-icons/fa';
import { FaEnvelope } from 'react-icons/fa';
import { FaWindowMaximize } from 'react-icons/fa';
import { FaCode } from 'react-icons/fa';
import { FaRegFileAlt } from 'react-icons/fa';

import { FaLinkedin } from 'react-icons/fa';
import { FaTwitterSquare } from 'react-icons/fa';


import logo from "../images/logo.png" // Tell Webpack this JS file uses this image

class IndexPage extends React.Component {


  render() {
    return (
      <Layout>
        <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
        <nav>
          <ul>
          <li className="home"><Link activeClass="active"
    to="home"
    spy={true}
    smooth={true}
    offset={-70}
    duration= {500}><img src={logo} className="logo" height="35" alt="Logo"/></Link></li>
          <li className="one"><Link activeClass="active"
    to="home"
    spy={true}
    smooth={true}
    offset={-70}
    duration= {500}>Home</Link></li>
          <li className="two"><Link activeClass="active"
    to="about"
    spy={true}
    smooth={true}
    offset={-70}
    duration= {500}>About</Link></li>
          <li className="three"><Link  activeClass="active"
          to="portfolio"
          spy={true}
          smooth={true}
          offset={-70}
          duration= {500}>Portfolio</Link></li>
          <li className="four"><Link  activeClass="active"
          to="contact"
          spy={true}
          smooth={true}
          offset={-70}
          duration= {500}>Contact</Link></li>
          </ul>
        </nav>
        <div id="home" className="bgimg-1">
          <div className="top">
            <h1><span>M</span>att <span>B</span>ayliss</h1>
            <p>Web Developer, Creative Writer / Designer based in Birmingham, UK.</p>
          </div>
        </div>
        <div className="main">
          <div id="about">
            <h2>About</h2>
            <p>I have been working within a mixture of digital, creative and inbound marketing agencies for the past 9
            years.</p>
            <p class="test">A proficient and conscientious web developer/problem solver with a keen eye for detail and an
            appreciation of good design. Works extremely well within a group environment, but can also be relied upon to
            efficiently carry out tasks unsupervised, preferably while listening to Iron Maiden.</p>
            <h3>Skills</h3>
            <div class="skill">
                <FaHtml5 />
                <p>HTML5</p>
            </div>
            <div class="skill">
                <FaCss3 />
                <p>CSS3</p>
            </div>
            <div class="skill">
                <FaLess />
                <p>LESS</p>
            </div>
            <div class="skill">
                <FaSass />
                <p>Sass</p>
            </div>
            <div class="skill">
                <FaPhp />
                <p>PHP</p>
            </div>
            <div class="skill">

                <p>MySql</p>
            </div>
            <div class="skill">
                <FaJs />
                <p>Javascript</p>
            </div>
            <div class="skill">
                <FaGit />
                <p>GIT</p>
            </div>
            <div class="skill">
                <FaBitbucket />
                <p>Bitbucket</p>
            </div>
            <div class="skill">
                <FaJira />
                <p>Jira</p>
            </div>
            <div class="skill">
                <FaLaravel />
                <p>Laravel</p>
            </div>
            <div class="skill">
                <FaWordpress />
                <p>WordPress</p>
            </div>
            <div class="skill">
                <FaHubspot />
                <p>Hubspot</p>
            </div>
            <div class="skill">
                <FaDrupal />
                <p>Drupal</p>
            </div>
            <div class="skill">
                <FaJoomla />
                <p>Joomla</p>
            </div>
            <div class="skill">
                <FaAdobe />
                <p>Photoshop</p>
            </div>
          </div>
          <div id="portfolio">
            <h2>Portfolio</h2>
            <p>Click through the below to see example of my work</p>
            <div id="design" class="type">
              <FaPencilAlt />
              <p>Design</p>
            </div>
            <div id="emails" class="type">
              <FaEnvelope />
              <p>Email</p>
            </div>
            <div id="frontend" class="type">
              <FaWindowMaximize />
              <p>Frontend</p>
            </div>
            <div id="backend" class="type">
                <FaCode />
                <p>Backend</p>
            </div>
            <div id="writing" class="type">
              <FaRegFileAlt />
              <p>Writing</p>
            </div>

            <div class="slider">
              <div data-type="design">
                <h3>Design</h3>
                <p>When working with Designers I am offen asked about best practise, I also suggest ways to help the development process and ease usability. I have created wireframes for a few projects.</p>
              </div>
              <div data-type="emails">
                <h3>Email Marketing</h3>
                <p>I have been building emails for a long time now, i have worked with Halfords, SKODA, Renault, KFC, Nestle Ceral Partners, Namco, Marstons. Here are just a few examples:</p>
              </div>
              <div data-type="frontend">
                <h3>Frontend Projects</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tempor pretium nibh. Vestibulum atcmetus eget magna interdum vestibulum quis sed tortor.</p>
              </div>
              <div data-type="backend">
                <h3>Backend Projects</h3>
                <p>I have been involved in a few backend project most of these are not i cant really show off, below are a few breif examples</p>
              </div>
              <div data-type="writing">
                <h3>Writing</h3>
                <p>I decided to include this section because in my spare time I am working on a few books, I haven't yet published anything but I am getting close</p>
              </div>

              <div class="examples">
                <div data-type="design">
                  <img src="images/portfolio/skoda/thumb.png" alt="&Scaron;KODA emails"/>
                  <h4>&Scaron;KODA responsive design</h4>
                  <p>description</p>
                </div>
                <div data-type="design">
                  <img src="images/portfolio/skoda/thumb.png" alt="&Scaron;KODA emails"/>
                  <h4>Electrium website rebuild</h4>
                  <p>Wireframe</p>
                </div>
                <div data-type="design">
                  <img src="images/portfolio/skoda/thumb.png" alt="&Scaron;KODA emails"/>
                  <h4>Namco Booking System</h4>
                  <p>Wireframe</p>
                </div>
                <div data-type="design">
                  <img src="images/portfolio/skoda/thumb.png" alt="&Scaron;KODA emails"/>
                  <h4>Bella Italia Email</h4>
                  <p>Wireframe</p>
                </div>
                <div data-type="design">
                  <img src="images/portfolio/skoda/thumb.png" alt="&Scaron;KODA emails"/>
                  <h4>Movie Night Email / Landing Pages</h4>
                  <p>design</p>
                </div>

                <div data-type="emails">
                  <img src="images/portfolio/skoda/thumb.png" alt="&Scaron;KODA emails"/>
                  <h4>&Scaron;KODA</h4>
                  <p>description</p>
                </div>
                <div data-type="emails">
                  <img src="images/portfolio/namco/thumb.png" alt="Namco emails"/>
                  <h4>Namco</h4>
                  <p>description</p>
                </div>
                <div data-type="emails">
                  <img src="images/portfolio/cereal_partners/thumb.png" alt="Cereal Partners UK emails"/>
                  <h4>Cereal Partners UK</h4>
                  <p>description</p>
                </div>

                <div data-type="frontend">
                  <img src="images/portfolio/renault_lp/thumb.png" alt="Renault Landing Page"/>
                  <h4>Renault Landing Page</h4>
                  <p>The brief was to create a repsonsive data capture landing page for 3 new models that was going to be realeased in ireland for 3 delears</p>
                </div>
                <div data-type="frontend">
                  <img src="images/portfolio/sanderson/thumb.png" alt="Sanderson"/>
                  <h4>Sanderson</h4>
                  <p>Hubspot website</p>
                </div>
                <div data-type="frontend">
                  <img src="images/portfolio/ricks_place/thumb.png" alt="Ricks Place"/>
                  <h4>Ricks Place</h4>
                  <p>Wordpress website</p>
                </div>
                <div data-type="frontend">
                  <img src="images/portfolio/strongs/thumb.png" alt="Strongs"/>
                  <h4>Strongs</h4>
                  <p>Wordpress website</p>
                </div>
                <div data-type="frontend">
                  <img src="images/portfolio/helena_jane/thumb.png" alt="Helena Jane"/>
                  <h4>Helena Jane</h4>
                  <p>Wordpress website</p>
                </div>

                <div data-type="backend">
                  <img src="images/portfolio/dpm/thumb.png" alt="Digital Proof Manager"/>
                  <h4>Digital Proof Manager</h4>
                  <p>I identified a procress that was taking an excessive amount of time done manaully, I conceptualize the procress and pitched it to the managment. Digital Assets (html, gif, flash banners) could be uploaded and views by the client in a secure way, for them to approved or rejected. The history of the asset was logged and nofications sent when amends had been procressed.</p>
                </div>
                <div data-type="backend">
                  <img src="images/portfolio/supplier_list/thumb.png" alt="Supplier List"/>
                  <h4>Supplier List</h4>
                  <p>This was created to eliminate a manaul procress of a developer having to update a SQL database, the tool was create so a none technical person could update a supplier contact details or add/remove a supplier.</p>
                </div>
                <div data-type="backend">
                  <img src="images/portfolio/competitor_prices/thumb.png" alt="Competitor Prices"/>
                  <h4>Competitor Prices</h4>
                  <p>The brief was to create tool that would provide a list of competitor prices and compare them. a report could then be created to see how prices compared and when prices changed.</p>
                </div>
                <div data-type="backend">
                  <img src="images/portfolio/skoda/thumb.png" alt="Movie Night"/>
                  <h4>Movie Night Landing Pages</h4>
                  <p>Landing page for members to choose the best date for them and to vote for a movie</p>
                </div>

                <div data-type="writing">
                  <img src="images/portfolio/black_door/thumb.png" alt="Behind the Black Door"/>
                  <h4>Behind the Black Door</h4>
                  <p></p>
                </div>
                <div data-type="writing">
                  <img src="images/portfolio/thomas_bohan/thumb.png" alt="The Memoirs of Thomas Bohan"/>
                  <h4>The Memoirs of Thomas Bohan</h4>
                  <p></p>
                </div>

              </div>
            </div>

          </div>

        </div>
        <div id="contact" className="bgimg-2">
          <div className="float">
              <h2>Contact</h2>

              <form name="contact" method="post" action="/success" data-netlify="true" data-netlify-honeypot="bot-field">
                  <div className="field half first">
                      <label htmlFor="name">Name</label>
                      <input type="text" name="name" id="name" required/>
                  </div>
                  <div className="field half">
                      <label htmlFor="email">Email</label>
                      <input type="text" name="email" id="email" required/>
                  </div>
                  <div className="field">
                      <label htmlFor="message">Message</label>
                      <textarea name="message" id="message" rows="6" required></textarea>
                  </div>
                  <div>
                    <input type="hidden" name="form-name" value="contact" />
                    <input type="hidden" name="bot-field" />
                  </div>
                  <div className="actions">
                      <input type="submit" value="Send Message" className="special" />
                  </div>
              </form>


              <a href="http://uk.linkedin.com/pub/matt-bayliss/3b/9bb/206"><FaLinkedin /></a>
              <a href="https://twitter.com/whatsup_matt"><FaTwitterSquare /></a>

          </div>
      </div>
      </Layout>
    )
  }
}

export default IndexPage
